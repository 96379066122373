import { DoctorEntity } from '@/interfaces/DoctorEntity';
import BackendAPI from '@/api/BackendAPI';

export default class DoctorEntityService extends BackendAPI {
  constructor() {
    super();
  }

  public async fetchDoctorEntity(doctorId: string): Promise<DoctorEntity> {
    const endpoint = '/doctors/' + doctorId + '/entity';
    const response = await this.doCallSecured(endpoint, 'GET');
    return await response.json();
  }

  public async fetchDoctorNextAvailableContractNumber(): Promise<number> {
    const endpoint = '/doctors/entity/next-available-contract-number';
    const response = await this.doCallSecured(endpoint, 'GET');
    const data = await response.json();

    return data.contractNumber;
  }
}
