import { DoctorPayoutInfo } from "@/interfaces/DoctorPayoutInfo";
import { PayoutInfo } from "@/interfaces/PayoutInfo";
import KeyCloakService from "../api/KeyCloakService";
import { AppContext } from "@/config/AppContext";

export default {
  async fetchPayoutInfos(): Promise<DoctorPayoutInfo[]> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url = AppContext.getApiUrlPath() + "/payouts";
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch doctors:" + response);
        throw new Error("Unable to fetch doctors");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Unable to fetch doctors");
    }
  },
  async fetchPayoutHistory(doctorId: string): Promise<PayoutInfo[]> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() + "/payouts/history?doctorId=" + doctorId;
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch payout history:" + response);
        throw new Error("Unable to fetch payout history");
      }
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      throw new Error("Unable to fetch doctors");
    }
  },
  async fetchLatestPayout(doctorId: string): Promise<PayoutInfo | null> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() + "/payouts/latest?doctorId=" + doctorId;
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch latest payout:" + response);
        throw new Error("Unable to fetch latest payout");
      }
      let data;
      try {
        data = await response.json();
      } catch {
        data = null;
      }
      return data;
    } catch (error) {
      console.error(error);
      throw new Error("Unable to fetch latest payout");
    }
  },
  async fetchOngoingPayout(doctorId: string): Promise<PayoutInfo[]> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() + "/payouts/ongoing?doctorId=" + doctorId;
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch ongoing payout:" + response);
        throw new Error("Unable to fetch ongoing payout");
      }
      let data;
      try {
        data = await response.json();
      } catch {
        data = null;
      }
      return data;
    } catch (error) {
      console.error(error);
      throw new Error("Unable to fetch ongoing payout");
    }
  },
  async fetchPayoutInfoForDoctor(doctorId: string): Promise<DoctorPayoutInfo> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() + "/payouts/single?doctorId=" + doctorId;
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch doctor payout info:" + response);
        throw new Error("Unable to fetch doctor payout info");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Unable to fetch doctor payout info");
    }
  },
  async triggerPayoutForDoctor(doctorId: string): Promise<PayoutInfo> {
    const headers = {
      Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      "Content-Type": "application/json",
    };
    const url =
      AppContext.getApiUrlPath() + "/payouts/trigger?doctorId=" + doctorId;
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: null,
    });
    const createdPayout = await response.json();
    if (!response.ok) {
      if (response.status == 409) {
        throw new Error("A aparut o eroare la procesul de decontare manuala.");
      } else {
        console.error(response);
        throw new Error("A aparut o eroare la procesul de decontare manuala.");
      }
    }
    return createdPayout;
  },
  async fetchDefaultCommission(): Promise<number> {
    const headers = {
      Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      "Content-Type": "application/json",
    };
    const url = AppContext.getApiUrlPath() + "/company";
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
      body: null,
    });
    const company = await response.json();
    if (!response.ok) {
      if (response.status == 409) {
        throw new Error("A aparut o eroare la procesul de decontare manuala.");
      } else {
        console.error(response);
        throw new Error("A aparut o eroare la procesul de decontare manuala.");
      }
    }
    return company.defaultCommissionPercent;
  },
};
